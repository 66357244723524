import ModalSocialShare from 'components/Modals/SocialShare'
import { useThingController } from 'containers/Thing/controllers/Thing.controller'
import { ThingProps } from 'containers/Thing/types/Thing.types'
import { useFileType } from 'hooks/useFileType'
import {
  EIconName,
  MbDropdownMenu,
  MbIcon,
  MbMenuWrapper,
  MbThingCard,
  MbTooltip
} from 'mintbase-ui'
import Image from 'next/image'
import Link from 'next/link'
import { useContext, useEffect, useState } from 'react'
import { ThemeContext, ThemeContextType } from 'services/providers/ThemeContext'
import { getCurrentTheme } from 'utils/currentTheme'

const ThingCard = (props: ThingProps) => {
  const {
    store,
    storeDisplayName,
    thingMetadata,
    thingListing,
    likesCounter,
    isThingFetching,
    minter,
    isThingAlreadyLiked,
    handleLikeBtnClick,
    handleCardClick,
    handleOptionsClick,
    handleMinterClick,
  } = useThingController(props)
  const { theme }: ThemeContextType = useContext(ThemeContext)

  const { id, tokenId, title, price, url, hideMintbaseContract, isDeeplink } =
    props

  const [openExtraMenu, setOpenExtraMenu] = useState(false)
  const [openShareModal, setOpenShareModal] = useState(false)
  const [redirectUrl, setRedirectUrl] = useState(url || `/thing/${id}`)

  const items = [
    {
      content: <span>Share...</span>,
      onClick: () => setOpenShareModal(!openShareModal),
    },
  ]

  const likeIcon = isThingAlreadyLiked ? EIconName.LIKE_FILLED : EIconName.LIKE

  const nftType = thingMetadata.animationType || 'image'

  const thingPrice =
    Number(thingListing.price) > 0
      ? `${Number(thingListing.price).toFixed(2)} N`
      : '-'

  const baseCardInfo = {
    // upperLeftText: Number(likesCounter).toString(),
    // upperLeftIcon: likeIcon,
    nftTypeIcon: useFileType(nftType).icon,
    midLeftText: thingMetadata.title,
    midRightText: isDeeplink ? null : `${price ?? thingPrice}`,
    onBotLeftImageClick: handleMinterClick,
    onCenterElementClick: handleCardClick,
    // onUpperLeftClick: handleLikeBtnClick,
    centerElement: (
      <div className="relative h-full w-full">
        <Link href={redirectUrl} passHref>
          <a>
            <Image
              layout="fill"
              objectFit="contain"
              placeholder="blur"
              blurDataURL="/images/mintbase-symbol-250x250.png"
              src={
                thingMetadata?.coverImage ||
                '/images/mintbase-symbol-250x250.png'
              }
              alt={title}
            ></Image>
          </a>
        </Link>
      </div>
    ),
  }

  useEffect(() => {
    if (!tokenId) return
    setRedirectUrl(`/thing/${id}?tokenId=${tokenId}`)
  }, [id, tokenId])

  return (
    <>
      <a>
        <MbThingCard
          cardInfo={{
            ...baseCardInfo,
            extraMidLeftElement: !isDeeplink &&
              !hideMintbaseContract &&
              store && (
                <div className="flex gap-4 items-center">
                  <p className="truncate">{storeDisplayName || store.name}</p>
                  <MbTooltip
                    component={
                      <MbIcon
                        name={
                          getCurrentTheme(theme) === 'light'
                            ? EIconName.MINTBASE_LIGHT_BADGE
                            : EIconName.MINTBASE_DARK_BADGE
                        }
                        color=""
                        size="20px"
                      />
                    }
                    id={`mb-contract-${id}`}
                    place="right"
                    text="Mintbase Contract"
                  />
                </div>
              ),
            upperRightElement: !isDeeplink && (
              <div className="relative">
                <MbMenuWrapper setIsOpen={setOpenExtraMenu}>
                  <div onClick={() => setOpenExtraMenu(!openExtraMenu)}>
                    <MbIcon
                      name={EIconName.MORE}
                      size="20px"
                      color="black"
                      darkColor="white"
                    />
                  </div>
                  <MbDropdownMenu
                    items={items}
                    isOpen={openExtraMenu}
                    className="right-0"
                  />
                </MbMenuWrapper>
              </div>
            ),
            botLeftImage: !isDeeplink && minter?.avatar,
            botRightText:
              !isDeeplink &&
              `${
                thingListing.tokensListedSaleCounter +
                thingListing.tokensListedAuctionCounter
              }/${thingListing.tokensTotal}`,
            botRightIcon: !isDeeplink && EIconName.EDITIONS,
          }}
          loading={isThingFetching || !thingMetadata}
        />
      </a>
      <ModalSocialShare
        openShareModal={openShareModal}
        setOpenShareModal={setOpenShareModal}
        thingId={props.id}
        imageUrl={
          thingMetadata?.coverImage || '/images/mintbase-symbol-250x250.png'
        }
      />
    </>
  )
}

export default ThingCard
