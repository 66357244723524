import { useState, useEffect, useContext } from 'react'
import { useLazyQuery } from '@apollo/client'
import { bigToNear, parseYactoToNear, yoctoToNear } from 'lib/numbers'
import {
  getFirebaseDoc,
  firebaseCollections,
  getFirebaseCollection,
} from 'services/firebase'
import devLog from 'utils/devLog'
import { useAppContext } from 'context'
import {
  ThingProps,
  ThingMetadata,
  ThingListing,
  HasuraThingListing,
  HasuraTokenMetadata,
} from '../types/Thing.types'
import { useRouter } from 'next/router'
import { profileEmptyImage } from 'containers/UserImage/controllers/UserImage.controller'
import { ThemeContextType, ThemeContext } from 'services/providers/ThemeContext'
import { getCurrentTheme } from 'utils/currentTheme'
import {
  GET_COMBINED_THING_DATA,
  GET_THING_MINTERS_AGG,
  GET_TOKEN_BY_ID,
  GET_TOKEN_BY_THING_ID,
} from 'queries/things.graphql'

const fallbackThingMetadata = {
  title: '',
  coverImage: '',
  likesCounter: 0,
  description: 'No description',
  animationUrl: '',
  animationType: null,
  document: null,
  extra: null,
}

const fallbackThingListing = {
  tokensListedSaleCounter: 0,
  tokensListedAuctionCounter: 0,
  tokensTotal: 10,
  price: '1',
  tokenId: '',
}

interface ThingMinter {
  walletAddress: string
  avatar: string
}

const useThingController = ({
  id,
  title,
  description,
  coverImage,
  likesCounter,
  animationUrl,
  tokensCounter,
  tokensTotal,
  price,
  metadata,
}: ThingProps) => {
  const {
    state: { user },
  } = useAppContext()

  const router = useRouter()

  const [thingMetadata, setThingMetadata] = useState<ThingMetadata | null>(
    metadata
  )
  const [thingListing, setThingListing] = useState<ThingListing | null>(null)
  const [isThingAlreadyLiked, setIsThingAlreadyLiked] = useState(false)
  const [likesAmount, setLikesAmount] = useState<number | null>(null)
  const [minter, setMinter] = useState<ThingMinter | null>(null)
  const [hasMultipleMinters, setHasMultipleMinters] = useState(false)
  const [uniqueOwnersAmount, setUniqueOwnersAmount] = useState<number | null>(
    null
  )
  const [storeDisplayName, setStoreDisplayName] = useState('')
  const { theme }: ThemeContextType = useContext(ThemeContext)

  const [store, setStore] = useState<{
    id?: string
    name?: string
    baseUri?: string
  } | null>(null)

  const [fetchCombinedThingData, { loading: isThingFetching }] = useLazyQuery(
    GET_COMBINED_THING_DATA,
    { variables: { thingId: id } }
  )

  const [fetchThingMintersAgg] = useLazyQuery(GET_THING_MINTERS_AGG, {
    variables: { thingId: id },
  })

  // Combined graphql query
  useEffect(() => {
    updateThingData()
  }, [id])

  // Firebase queries.
  useEffect(() => {
    updateThingLikesAmount()
  }, [id])

  useEffect(() => {
    if (!user) return
    updateUserThingLikes()
  }, [user])

  const updateThingData = async () => {
    const { data }: { data: HasuraThingListing } =
      await fetchCombinedThingData()

    const { data: aggData } = await fetchThingMintersAgg()

    if (!data) return
    // list data
    const [list] = data.list

    const mTokensListedSaleCounter = data.simpleSaleCount.aggregate.count
    const mTokensListedAuctionCounter = data.rollingAuctionCount.aggregate.count
    const mTokensTotal = data.tokens_aggregate.aggregate.count
    const mPrice = list ? bigToNear(list.price, 0) : '0'

    setThingListing({
      tokensListedSaleCounter: tokensCounter ?? mTokensListedSaleCounter,
      tokensListedAuctionCounter: mTokensListedAuctionCounter ?? 0,
      tokensTotal: tokensTotal ?? mTokensTotal,
      price: price ?? mPrice.toString(),
      tokenId: list?.token?.id,
    })

    // metadata data
    const [thing] = data.metadata
    setThingMetadata({
      ...thingMetadata,
      title: title ?? thing?.metadata?.title,
      description: description ?? thing?.metadata?.description,
      coverImage: coverImage ?? thing?.metadata?.media,
      animationUrl: animationUrl ?? thing?.metadata?.animation_url,
      animationType: thing?.metadata?.animation_type,
      document: thing?.metadata?.document,
      extra: thing?.metadata?.extra,
    })

    // set store from meta query
    setStore({
      id: thing?.store?.id,
      name: thing?.store?.name,
      baseUri: thing?.store?.baseUri,
    })

    fetchStoreTitleInfo(thing?.store?.id)

    // update unique owners
    setUniqueOwnersAmount(data.ownersCount.aggregate?.count)

    // update minter avatar
    const [token] = thing?.tokens || []
    const avatar = await getMinterAvatar(token?.minter)
    setMinter({ walletAddress: token?.minter, avatar })

    if (aggData?.tokens_aggregate) {
      setHasMultipleMinters(aggData.tokens_aggregate.aggregate.count > 1)
    }
  }

  const fetchStoreTitleInfo = async (id: string) => {
    if (!id) return
    const collection = getFirebaseCollection(firebaseCollections.STORE)

    const collectionSnap = await collection.where('contract', '==', id).get()

    if (collectionSnap.empty) {
      return
    }
    const [doc] = collectionSnap.docs

    setStoreDisplayName(doc.data().displayName)
  }

  const getThingFirebaseDoc = async () => {
    const thing = await getFirebaseDoc(firebaseCollections.THING, id).get()
    const data = thing.data()
    return data
  }

  const updateThingLikesAmount = async () => {
    if (likesCounter) {
      return setLikesAmount(likesCounter)
    }
    const thing = await getThingFirebaseDoc()
    // TODO: create type for the thing object from firebase and remove type casting
    const likes = !thing || !thing.votes ? 0 : (thing.votes as number)

    setLikesAmount(likes)
  }

  const getMinterAvatar = async (walletAddress: string) => {
    const defaultAvatar = profileEmptyImage[getCurrentTheme(theme)]

    if (!walletAddress) {
      devLog(
        'no walletAddress value has been passed',
        `ThingCard/getMinterAvatar`
      )
      return defaultAvatar
    }

    const firebaseCollection = await getFirebaseCollection(
      firebaseCollections.USER
    )
      .where('walletAddress', '==', walletAddress)
      .get()
    if (firebaseCollection.empty) {
      devLog(
        `there is no user on our firebase database with the walletAddress ${walletAddress}`,
        `ThingCard/getMinterAvatar`
      )
      return defaultAvatar
    }

    const [doc] = firebaseCollection.docs

    const { profileImage } = doc.data()
    if (!profileImage) {
      devLog(
        `user ${walletAddress} has no profile image. Using defaultAvatar`,
        `ThingCard/getMinterAvatar`
      )
      return defaultAvatar
    }

    //TODO: add firebase user document interface and remove type casting
    return profileImage as string
  }

  const updateUserThingLikes = async () => {
    const doesUserReallyLikesThisThing = await getUserThingLikes()
    setIsThingAlreadyLiked(doesUserReallyLikesThisThing)
  }

  const getVoteDocByUserThing = async (mThingId: string, userId: string) => {
    const firebaseCollection = await getFirebaseCollection(
      firebaseCollections.VOTE
    )
      .where('thingId', '==', mThingId)
      .where('userId', '==', userId)
      .get()

    if (firebaseCollection.empty) return null

    const [doc] = firebaseCollection.docs
    return doc
  }

  const getUserThingLikes = async () => {
    if (!user) return false

    const firebaseDoc = await getVoteDocByUserThing(id, user.id)
    if (!firebaseDoc || !firebaseDoc.exists) return false

    const docData = firebaseDoc.data()

    if (!docData || !docData.account) return false

    if (user.walletAddress === docData.account) return true

    return false
  }

  const setFirebaseVoteDoc = async (likesCounter: number) => {
    if (!user) return

    const [metaId] = id.split(':')
    const network = 'testnet'
    const chain = 'near'
    const votedOn = new Date()
    const account = user.walletAddress

    const newVote = {
      vote: likesCounter,
      metaId,
      id,
      network,
      chain,
      votedOn,
      account,
      userId: user.id,
    }

    const firebaseThingDoc = await getVoteDocByUserThing(id, user.id)

    if (!firebaseThingDoc) {
      const newFirebaseVoteDoc = getFirebaseCollection(
        firebaseCollections.VOTE
      ).doc()

      const result = await newFirebaseVoteDoc.set(newVote)
    }

    const resultt = getFirebaseCollection(firebaseCollections.VOTE)
      .doc()
      .update({
        vote: likesCounter,
      })
  }

  const handleLikeBtnClick = () => {
    if (!user) {
      // TODO: implement login prompt in the form of modal that the user can dismiss.
      // Do not redirect the user to an auth page, it might have been a missclick on the thing card
      return
    }

    setIsThingAlreadyLiked(!isThingAlreadyLiked)

    const newLikesCounter = isThingAlreadyLiked
      ? likesCounter - 1
      : likesCounter + 1

    setLikesAmount(newLikesCounter)
    void setFirebaseVoteDoc(newLikesCounter)
  }
  const handleCardClick = () => {
    // TODO: navigate to the thing page
    router.push(`/thing/${id}`)
    return null
  }
  const handleOptionsClick = () => {
    // TODO: implement the dropdown menu
    return null
  }
  const handleMinterClick = () => {
    router.push(`/human/${minter.walletAddress}`)
    return null
  }

  return {
    store: store,
    thingMetadata: thingMetadata ?? fallbackThingMetadata,
    thingListing: thingListing ?? fallbackThingListing,
    likesCounter: likesAmount,
    uniqueOwnersCounter: uniqueOwnersAmount,
    animationUrl,
    minter,
    hasMultipleMinters,
    isThingFetching,
    isThingListingFetching: isThingFetching,
    isThingAlreadyLiked,
    storeDisplayName,
    handleLikeBtnClick,
    handleCardClick,
    handleOptionsClick,
    handleMinterClick,
  }
}

const useTokenController = ({
  id,
  tokenId,
  metadata,
}: {
  id: string
  tokenId?: string
  metadata?: ThingMetadata
}) => {
  const {
    store,
    storeDisplayName,
    thingMetadata,
    thingListing,
    likesCounter,
    isThingFetching,
    minter,
    isThingAlreadyLiked,
    handleLikeBtnClick,
    handleCardClick,
    handleOptionsClick,
    handleMinterClick,
    uniqueOwnersCounter,
  } = useThingController({
    id,
    metadata,
  })

  const [fetchTokenById, { loading: isFetchingTokenById }] =
    useLazyQuery(GET_TOKEN_BY_ID)

  const [fetchTokenByThingId, { loading: isFetchingTokenByThingId }] =
    useLazyQuery(GET_TOKEN_BY_THING_ID)

  const [token, setToken] = useState<HasuraTokenMetadata | null>(null)

  const fetchTokenData = async () => {
    if (tokenId) {
      const { data }: { data: { token: HasuraTokenMetadata[] } } =
        await fetchTokenById({ variables: { tokenId } })

      if (!data || !data.token || data.token.length === 0) {
        return
      }

      const { token } = data

      setToken(token[0])
    } else {
      const { data }: { data: { token: HasuraTokenMetadata[] } } =
        await fetchTokenByThingId({ variables: { thingId: id } })

      if (!data || !data.token || data.token.length === 0) {
        return
      }

      const { token } = data

      setToken(token[0])
    }
  }

  useEffect(() => {
    fetchTokenData()
  }, [id, tokenId])

  return {
    thing: {
      store,
      storeDisplayName,
      thingMetadata,
      thingListing,
      likesCounter,
      isThingFetching,
      minter,
      isThingAlreadyLiked,
      handleLikeBtnClick,
      handleCardClick,
      handleOptionsClick,
      handleMinterClick,
      uniqueOwnersCounter,
    },
    token: token,
  }
}

export { useThingController, useTokenController }
