const devLog = (message: string, from?: string) => {
  const isDevelopment = process.env.NEXT_PUBLIC_DEVELOPMENT
  if (isDevelopment) {
    console.group('DEVELOPMENT LOG')
    console.log(`from: ${from}`)
    console.log(message)
    console.groupEnd()
  }
}

export default devLog
